<template>
    <div class="content">
        <div class="main">
            <div class="main-r">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="全部订单" name="first"></el-tab-pane>
                    <el-tab-pane label="普通订单" name="second"></el-tab-pane>
                    <!-- <el-tab-pane label="比价订单" name="third"></el-tab-pane> -->
                    <el-tab-pane label="招标订单" name="fourth"></el-tab-pane>
                </el-tabs>

                <div class="div1">
                    <input type="text" placeholder="输入产品名称/订单号搜索" class="ipt1" ref="val">
                    <button class="btn1" @click="btn2" type="button">查询</button>
                </div>
                <div class="div2">
                    <div class="div2-1">产品信息</div>
                    <div class="price">单价</div>
                    <div class="number">数量</div>
                    
                    <div class="div2-2">订单金额</div>
                    <div class="types">类型</div>
                    <div class="status">
                        <el-dropdown placement="bottom-start" @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                {{info2}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                            <el-dropdown-menu slot="dropdown" placement="start">
                                <el-dropdown-item command="待付款">待付款</el-dropdown-item>
                                <el-dropdown-item command="制作中">制作中</el-dropdown-item>
                                <el-dropdown-item command="待收货">待收货</el-dropdown-item>
                                <el-dropdown-item command="交易完成">交易完成</el-dropdown-item>
                                <el-dropdown-item command="交易关闭">交易关闭</el-dropdown-item>
                                <el-dropdown-item command="全部状态">全部状态</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="div2-4">交易操作</div>
                </div>

                <div>
                    <ul class="ul1" v-for="(item,i) in list" :key="i">
                        <li>

                            <div class="divt">
                                <span class="span1">{{item.createDate}}</span>
                                <span class="span3">订单号：{{item.orderSn}}</span>
                                <span>{{item.storeShortName}}</span>

                                <div v-show="item.businessStatus==1" class="upload">
                                    <img src="../../../assets/image/dengdai.png" alt="">
                                    <span>等待商家上传业务单...</span>
                                </div>

                                <!-- 以下展示文件业务单样式 -->
                                 <div v-show="item.orderMaterielTo==undefined&&item.businessStatus==2 && (item.state==0 ||item.state==1 ||item.state==2)" class="echoWrap" @click="echoBtnfile(item.fileUrl)">
                                    <img src="../../../assets/image/wenben.png" alt="">
                                    <span>点击查看业务单</span>
                                    <!-- <a :href="''+type2(item.fileUrl)+''">下载文件</a> -->
                                    <!-- {{type2(item.fileUrl)}} -->
                                    <img src="../../../assets/image/jiantou.png" alt="">
                                </div>
                            
                                <div v-show="item.orderMaterielTo==undefined&&item.businessStatus==2 && (item.state==3 ||item.state==4)" class="echoWrap1" @click="echoBtnfile(item.fileUrl)">
                                    <!-- <img src="../../../assets/image/wenben.png" alt=""> -->
                                    <span>点击查看业务单</span>
                                    <!-- <a :href="''+type2(item.fileUrl)+''">下载文件</a> -->
                                    <!-- {{type2(item.fileUrl)}} -->
                                    <!-- <img src="../../../assets/image/jiantou.png" alt=""> -->
                                </div>
                              

                                <!-- 以下是在线业务单展示样式 -->
                                <div v-show="item.orderMaterielTo!=undefined && item.businessStatus==2 && (item.state==0 ||item.state==1 ||item.state==2)" class="echoWrap" @click="dialogVisible = true;echoBtn(item.orderSn)">
                                    <img src="../../../assets/image/wenben.png" alt="">
                                    <span >点击查看业务单</span>
                                    <img src="../../../assets/image/jiantou.png" alt="">
                                </div>

                                 <div v-show="item.orderMaterielTo!=undefined && item.businessStatus==2 && (item.state==3 ||item.state==4)" class="echoWrap1" @click="dialogVisible = true;echoBtn(item.orderSn)">
                                    <!-- <img src="../../../assets/image/wenben.png" alt=""> -->
                                    <span >点击查看业务单</span>
                                    <!-- <img src="../../../assets/image/jiantou.png" alt=""> -->
                                </div>
                             
                            </div>




                            <div class="divb" v-for="(item1,i2) in item.orderItemList" :key="i2">
                                <div class="divb-l">
                                    <div class="divb-l1">
                                        <template v-if="item.orderType === 3">
                                            <img src="https://imag.kqwad.com/upload/4b189f6ac9de45a6b0637140056fccd6.png"
                                                 alt=""/>
                                        </template>
                                        <template v-else>
                                            <div class="imgWrap">
                                                <img :src="item1.logoUrl | imagePath" alt>
                                            </div>

                                        </template>
                                        <div class="productWrap">
                                            <div class="product" style="margin-bottom:17px">{{item1.productName}}</div>
                                            <div v-for="(item,value) in item1.attrList" :key="value">
                                                <div
                                                        v-for="(n , index) in item"
                                                        :key="index"
                                                        style="margin-top:5px;color:#999;font-size:12px"
                                                >
                                                    <span>{{index}}:</span>
                                                    <span>{{n}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                            class="divb-l2"
                                            v-bind:style="{'z-index':item.orderType==2 ? '-5':'5'}"
                                    >￥{{item1.price | price}}
                                    </div>
                                    <div
                                            class="divb-l3"
                                            v-bind:style="{'z-index':item.orderType==2 ? '-5':'5'}"
                                    >{{item1.productNum}}
                                    </div>
                                </div>

                                <div class="divb-c">￥{{item.totalAmount |price}}</div>

                                <div class="divb-r">
                                    <div class="divb-rl">
                                        <p v-show="item.orderType==0">定价</p>
                                        <p v-show="item.orderType==1">议价</p>
                                        <p v-show="item.orderType==3">招标</p>
                                        <p v-show="item.orderType==4">极速</p>
                                    </div>
                                    <div class="divb-rc">
                                        <p class="p1" @click="Pay()">{{changeType1(item.state)}}</p>
                                        <router-link :to="'/orderDetail/'+item.orderSn">
                                            <p class="p2">订单详情</p>
                                        </router-link>
                                    </div>
                                    <div class="divb-rr">
                                        <!-- <button type="button" @click="statusValue(i,$event, item)" ref="statusValue" v-show="item.state!='3'" v-if="!item.state==1"> -->

                                        <!-- 无需业务单用户点击立即支付 -->
                                            <button type="button" @click="statusValue(i,$event, item)" ref="statusValue" v-if="item.businessStatus=='0'&&(item.state=='0'||item.state=='2'||(item.state=='4'&&item.orderType==0))" >
                                            {{changeType(item.state)}}
                                        </button>

                                        <!-- 商家未上传业务单时用户选择立即支付 -->
                                        <button type="button" @click="statusValue1(i,$event, item)" ref="statusValue" v-if="item.businessStatus=='1'&&(item.state=='0'||item.state=='2'||item.state=='4')" >
                                            {{changeType(item.state)}}
                                        </button>

                                        <!-- 商家已上传业务单时用户选择点击立即支付 -->
                                        <button type="button" @click="statusValue2(i,$event, item)" ref="statusValue" v-if="item.businessStatus=='2'&&(item.state=='0'||item.state=='2'||item.state=='4')" >
                                            {{changeType(item.state)}}
                                        </button>

                                        <p class="p2">
                                            <template>
                                                <el-button
                                                        type="text"
                                                        @click="open(i,$event)"
                                                        :style="{'display':item.state===1  || item.state===2 || item.state===3 || item.state===4 || item.state===5 ?  'none':'block'}"
                                                        class="cancel"
                                                >取消订单
                                                </el-button>
                                            </template>
                                        </p>
                                        <!-- 取消订单 -->
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- 以下是分页 -->
                <div class="pagtion">
                    <el-pagination
                            @size-change="sizeChangePage"
                            @current-change="currentChangePage"
                            :current-page="page"
                            :page-sizes="[10,20,30]"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalSize"
                    ></el-pagination>
                </div>
                <!-- 以上是分页· -->
            </div>
        </div>


  

             <div class="quickOrder">
            <el-dialog title="" :visible.sync="dialogVisible">
                       <div class="viewBusiness">
                    <div class="tit-wrap">
                        <p class="title">查看业务单</p>
                    </div>

                    <div class="basisInfo">
                        <div>
                            <p>订货日期：</p>
                            <p>{{basisInfoList.orderTime}}</p>
                        </div>
                        <div>
                            <p>交货日期：</p>
                            <p>{{basisInfoList.endTime}}</p>
                        </div>
                        <div class="orgName">
                            <p>订货单位：</p>
                            <p>{{basisInfoList.orgName}}</p>
                        </div>
                        <div>
                            <p>客户姓名：</p>
                            <p>{{basisInfoList.name}}</p>
                        </div>
                        <div>
                            <p>联系号码：</p>
                            <p>{{basisInfoList.phone}}</p>
                        </div>
                        <div class="orgAddr">
                            <p>客户地址：</p>
                            <p>{{basisInfoList.orgAddr}}</p>
                        </div>
                    </div>

                    <div class="tabWrap">
                        <template>
                            <el-table
                                ref="singleTable"
                                :data="tableData"
                                highlight-current-row
                                border
                                style="width: 75%">

                                <el-table-column
                                type="index"
                                label="序号"
                                width="50"
                            >
                                </el-table-column>

                                <el-table-column
                                prop="valuationType" label="计价类型（必填）" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                prop="projectType" label="项目类型（必填）" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                prop="projectContet" label="项目内容" style="width:6vw;"
                                >
                                </el-table-column>
                                
                                <el-table-column
                                property="name"
                                prop="texture" label="材质" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                property="name"
                                prop="projectType" label="项目类型（必填）" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                property="name"
                                prop="specs" label="规格" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                property="name"
                                prop="unit" label="单位" style="width:6vw;" 
                                >
                                </el-table-column>

                                <el-table-column
                                property="name"
                                prop="unitPrice" label="单价（必填）" style="width:6vw;"
                                >

                                </el-table-column>
                                <el-table-column
                                property="name"
                                prop="total" label="数量（必填）" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                property="makeRequire"
                                prop="total" label="制作要求" style="width:6vw;"
                                >
                                </el-table-column>

                                <el-table-column
                                property="name"
                                prop="price" label="金额" style="width:6vw;"
                                >
                                </el-table-column>


                            </el-table>
                        </template>
                    </div>

                    <div class="basisInfo1">
                        <div>
                            <p>提货要求：</p>
                            <p>{{basisInfoList.takeGoodRequare}}</p>
                        </div>
                        <div>
                            <p>安装要求：</p>
                            <p>{{basisInfoList.installRequare}}</p>
                        </div>
                        <div class="orgName">
                            <p>备注：</p>
                            <p>{{basisInfoList.materielMark}}</p>
                        </div>
                    
                    </div>

                    <div class="btnWrap">
                        <button @click="submit()" class="subBtn">下载业务单</button>
                        <button @click="cancelBtn()" class="cancelBtn" >关闭</button>
                    </div>

                </div>
            </el-dialog>
            </div>
     
    </div>
</template>

<script>
    import * as api from "@/api/api";
    import Aside from "@/components/common/aside.vue";
    // import popEcho from '@/components/common1/popEcho.vue'

    export default {
        name: "index",
        // components: {Aside,popEcho}, //注册组件
        components: {Aside}, //注册组件
        data() {
            return {
                hrefs:"", //下载文件地址
                 filePath:"", //文件本地服务器路径
                materielFlag:false, //业务单类型标识
                 dialogVisible: false, //业务单弹窗显示与否
                orderSn: "", //取消订单时的传递参数
                radio1: "上海",
                radio2: "上海",
                num1: 5,
                show: true,
                shows: false,
                info: "20",
                info1: "全部购买订单",
                info2: "订单状态",
                flagSelect: false,
                activeName: "first",

                page: 1, //当前显示第几页
                rows: 10, //一页显示多少行
                totalSize: 0, //总条数
                list: [],
                type: "", //订单默认传入0，显示所有类型数据
                isshow: false,
                val: "",
                txt: "", //模糊搜索查询参数
                status: "", //比价状态

                isActive: true,
                flag1: false,
                flag2: false,
                flag3: false,


                 tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],
        basisInfoList:[]
            };
        },
        mounted() {
            this.purchaseOrder();

            var that = this; //this的指向问题，下拉隐藏
            document.addEventListener("click", function (e) {
                e.cancelBubble = false;
                that.flagSelect = false; //这里that代表组件，this代表document
            });
        },
        created() {
            // this.foo();
        },
        methods: {
            type1(str){
                // console.log(str)
                // return str;
            },
            cancelBtn(){
                this.dialogVisible=false;
            },
            //导出文件业务单
            echoBtnfile(str){
                this.$confirm('您确定下载查看业务单文件吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
                }).then(() => {
                   window.location.href="https://imag.kqwad.com/"+str;
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
                });
            },
            // type1(str){
            //     if (str.materielDetailList.length>0) {
            //         return 1; //有在线业务单

            //     }else{
            //         return 0;
            //     }
    
            // }, 
        submit(){
            // 导出excel
            this.$confirm('您确定下载导出该业务单吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true

                }).then(() => {
                      let params={orderSn:this.orderSn}
                        this.$http.get('/excel/export',{"params":params,responseType: 'blob'}).then(res => {
                        let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                    // })
                    // let params={"orderSn":this.orderSn,"responseType": 'blob'}
                        // this.$http.post('/excel/export',{"params":params,responseType: 'blob'}).then(res => {
                    //     this.$http.get('/excel/export?orderSn='+this.orderSn+"&responseType=blob").then(res => {

                        
                    //     let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});
                    //     let url = window.URL.createObjectURL(blob);
                    //    window.location.href = url;
                    //     // console.log(333)
                            // this.dialogVisible=false;
                    }).catch(err=>{
                        console.log(err)
                    })  
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
                });
            

        },
    
         getList(sn){
            this.$http.get('/tOrder/materiel/detail?orderSn='+sn).then(res => {
                // this.form=res.data.data;
                this.basisInfoList=res.data.data;
                this.tableData=res.data.data.materielDetailList;
            })
        },



            echoBtn(sn){
                this.orderSn=sn;
                this.getList(sn);
                // console.log(this.orderSn)
            },


            statusValue(i, e, order,f) {
                //  let statusValue = this.$refs.statusValue[i].innerText; //当前点击的交易操作值
                 let statusValue = e.path[0].innerText; //当前点击的交易操作值

                if (statusValue == "重新购买") {
                    var param = {orderSn: order.orderSn};
                    api.buyAgain(param).then(res => {
                        if (res.data.code === 0 || res.data.code === 200) {
                            this.$router.push({
                                name: 'productDetail',
                                query: {sid: res.data.data.sid, pid: res.data.data.pid}
                            });
                        }
                        /* else {
                                                    this.$alert("", res.data.data);
                                                }*/
                    });
                }
                if (statusValue == "立即支付") {
                    const {orderSn} = order;
                    this.$router.push({name: 'paystatus', params: {orderSn}})
                }
                if (statusValue == "确认收货") {
                    this.$http.post('/storeMoney/confirmReceipt?orderSn=' + order.orderSn).then(res => {
                        const code = res.data.code;
                        if (code === 0 || code === 200) {
                            this.$message({
                                type: 'success',
                                message: '确认收货成功!'
                            });
                            this.foo();
                        } else {
                            this.$message({
                                type: 'failed',
                                message: '操作失败!'
                            });
                        }
                    });
                }
                if (statusValue == "立即评价") {
                     this.$message({
                        message: '暂无立即评价功能，敬请期待!',
                        type: 'warning'
                    });
                }
            },

            statusValue1(i, e, order){
                let self=this;
            this.$confirm('商家还未上传业务单,现在确认支付系统将默认您不需要业务单','确认立即支付吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                  //  let statusValue = this.$refs.statusValue[i].innerText; //当前点击的交易操作值
                 let statusValue = e.path[0].innerText; //当前点击的交易操作值

                if (statusValue == "重新购买") {
                    var param = {orderSn: order.orderSn};
                    api.buyAgain(param).then(res => {
                        if (res.data.code === 0 || res.data.code === 200) {
                            this.$router.push({
                                name: 'productDetail',
                                query: {sid: res.data.data.sid, pid: res.data.data.pid}
                            });
                        }
                        /* else {
                                                    this.$alert("", res.data.data);
                                                }*/
                    });
                }
                if (statusValue == "立即支付") {
                    const {orderSn} = order;
                    this.$router.push({name: 'paystatus', params: {orderSn}})
                }
                if (statusValue == "确认收货") {
                    this.$http.post('/storeMoney/confirmReceipt?orderSn=' + order.orderSn).then(res => {
                        const code = res.data.code;
                        if (code === 0 || code === 200) {
                            this.$message({
                                type: 'success',
                                message: '确认收货成功!'
                            });
                            this.foo();
                        } else {
                            this.$message({
                                type: 'failed',
                                message: '操作失败!'
                            });
                        }
                    });
                }
                if (statusValue == "立即评价") {
                     this.$message({
                        message: '暂无立即评价功能，敬请期待!',
                        type: 'warning'
                    });
                }             
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
                });
            },

            statusValue2(i, e, order){
                let self=this;
            this.$confirm('请确认本订单业务单内容与商家实际沟通一致，支付后将以此业务单作为唯一售后维权凭证','确认立即支付吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                  //  let statusValue = this.$refs.statusValue[i].innerText; //当前点击的交易操作值
                 let statusValue = e.path[0].innerText; //当前点击的交易操作值

                if (statusValue == "重新购买") {
                    console.log("跳转购买页面")
                    var param = {orderSn: order.orderSn};
                    api.buyAgain(param).then(res => {
                        if (res.data.code === 0 || res.data.code === 200) {
                            this.$router.push({
                                name: 'productDetail',
                                query: {sid: res.data.data.sid, pid: res.data.data.pid}
                            });
                        }
                        /* else {
                                                    this.$alert("", res.data.data);
                                                }*/
                    });
                }
                if (statusValue == "立即支付") {
                    const {orderSn} = order;
                    this.$router.push({name: 'paystatus', params: {orderSn}})
                }
                if (statusValue == "确认收货") {
                    this.$http.post('/storeMoney/confirmReceipt?orderSn=' + order.orderSn).then(res => {
                        const code = res.data.code;
                        if (code === 0 || code === 200) {
                            this.$message({
                                type: 'success',
                                message: '确认收货成功!'
                            });
                            this.foo();
                        } else {
                            this.$message({
                                type: 'failed',
                                message: '操作失败!'
                            });
                        }
                    });
                }
                if (statusValue == "立即评价") {
                     this.$message({
                        message: '暂无立即评价功能，敬请期待!',
                        type: 'warning'
                    });
                }                
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
                });
            },

            open0() { //点击立即支付

            },
            //以下分页
            //选择每页显示条数触发
            sizeChangePage(val) {
                let self = this;
                self.rows = val;
                // console.log(`每页 ${val} 条`);
                self.foo(self.rows); //请求接口
            },
            //选择第几页触发
            currentChangePage(val) {
                // console.log(`当前页: ${val}`);
                let self = this;
                self.page = val;
                self.foo(self.page);
            },
            changeType(str) {
                //通过状态码改变右侧交易状态的内容，在上方调用
                return str === 0
                    ? "立即支付"
                    : str === 1
                        ? "上传素材"
                        : str === 2
                            ? "确认收货"
                            : str === 3
                                ? "立即评价"
                                : str === 4
                                    ? "重新购买"
                                    : "";
            },
            changeType1(str) {
                //点击下拉菜单，改变订单状态
                return str === 0
                    ? "待付款"
                    : str === 1
                        ? "制作中"
                        : str === 2
                            ? "待收货"
                            : str === 3
                                ? "交易完成"
                                : str === 4
                                    ? "交易关闭"
                                    : "";
            },
            showCont() {
                this.show == true, (this.shows = false);
            },
            showConts() {
                this.show == false, (this.shows = true);
            },
            foo() {
                let self = this;
                let searchParams = {
                    page: self.page,
                    rows: self.rows,
                    type: self.type,
                    txt: self.txt,
                    status: self.status
                };
                api.purchaseOrder(searchParams).then(res => {
                    self.list = res.data.data.resData;
                    self.totalSize = res.data.data.totalSize; //总条数
                    

                    console.log(res.data.data.resData.fileUrl)
                     self.hrefs = "https://imag.kqwad.com/"+res.data.data.resData.fileUrl;
                    self.filePath = "https://imag.kqwad.com/"+res.data.data.resData.filePath;
                });
            },
            handleClick(tab) {
                let str = tab.label;
                if (str == "全部订单") {
                    this.type = "";
                }
                if (str == "普通订单") {
                    this.type = 0;
                }
                if (str == "比价订单") {
                    this.type = 1;
                }
                if (str == "招标订单") {
                    this.type = 2;
                }
                self.txt = "";
                self.status = "";
                this.purchaseOrder();
                // console.log(tab.label)
            },
            btn1() {
                //点击按钮下拉菜单显示
                this.flagSelect = true;
            },
            btn2() {
                //点击查询
                let val = this.$refs.val.value;
                this.txt = val;
                let self = this;
                let searchParams = {
                    page: self.page,
                    rows: self.rows,
                    type: self.type,
                    type: self.type,
                    txt: self.txt,
                    status: self.status
                };
                if (self.txt=='') {
                    return;
                }
                api.purchaseOrder(searchParams).then(res => {
                    //上方数据传进myPrice这个api内的params
                    let list = res.data.data.resData;
                    self.list = list;
                });
                this.$refs.val.value = "";
                console.log(this.$refs.val.value);
            },
            handleCommand(command) {
                //点击下拉列表，变换头部内容
                this.info2 = command;
                if (command == "待付款") {
                    this.status = 0;
                }
                if (command == "制作中") {
                    this.status = 1;
                }
                if (command == "待收货") {
                    this.status = 2;
                }
                if (command == "交易完成") {
                    this.status = 3;
                }
                if (command == "交易关闭") {
                    this.status = 4;
                }
                if (command == "全部状态") {
                    this.status = "";
                }
                this.txt = "";
                let self = this;
                let searchParams = {
                    page: self.page,
                    type: self.type,
                    txt: self.txt,
                    rows: self.rows,
                    status: self.status
                };
                api.purchaseOrder(searchParams).then(res => {
                    self.list = res.data.data.resData;
                    // console.log(res);
                    self.totalSize = res.data.data.totalSize; //共几条数据
                });
            },
            purchaseOrder() {
                let self = this;
                let searchParams = {
                    page: self.page,
                    rows: self.rows,
                    type: self.type,
                    txt: self.txt,
                    status: self.status
                };
                api.purchaseOrder(searchParams).then(res => {
                    let list = res.data.data.resData;
                    self.list = list;
                    self.totalSize = res.data.data.totalSize; //共几条数据
                    // console.log(self.list[0].businessStatus,444,res);
                });
            },

            open(i) {
                //此为取消订单
                this.$confirm("确定要取消订单吗?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: 'warning',
                    center: true
                }).then(() => {
                    let self = this;
                    this.orderSn = this.list[i].orderSn;
                    // console.log(this.orderSn)
                    let Params = {orderSn: this.orderSn, status: 5};
                    api.cancelOrder(Params).then(res => {
                        this.foo();
                        // console.log(res)
                    this.$message({
                        type: "success",
                        message: "取消订单成功!"
                    })
                    });
                    
                   
                }).catch(() => { //未取消订单
                    // console.log(333)
                })
                ;
            },

            open2(event) {
                this.$confirm("是否确认收货？", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    center: true
                }).then(() => {
                    this.$message({
                        type: "success",
                        message: "确认收货成功!"
                    });
                });
            },

            aaa(event) {
                console.log(this.$refs, 3333333);
            }
        },
        
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";

    * {
        margin: 0;
        padding: 0;
    }

    h2,
    ul,
    li {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
        border: 1px solid #e5e5e5;
        margin-top: 20px;
    }

    .div1 {
        display: flex;
    }

    //--------------以下是弹出框样式----------------//

    //--------------以上是弹出框样式----------------//
    .content button {
        color: #fff !important;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #333;
    }

    /deep/ .cancel span {
        color: #333 !important;
        cursor: pointer;
    }

    //............以下是下拉菜单样式........... //
    .el-dropdown-menu {
        width: 95px;
        text-align: center;
        top: 0px;
        font-size: 16px;
        margin-top: -2px;

        li {
            margin: 0;
            border-bottom: none;
        }
    }

    .el-dropdown {
        width: 100px;
        text-align: center;
        cursor: pointer !important;
        font-size: 16px;
        color: #333;
    }

    //............以上是下拉菜单样式........... //
    .content {
        font-family: "微软雅黑";
        width: 100%;
        height: 100%;
        background: #f5f5f5;

        button {
            border-color: #f3f3f3;
            font-family: "微软雅黑";
        }

        .main {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            font-size: 15px;

            .main-l {
                width: 181px;
                height: 1129px;
                background: #fff;
                float: left;
            }

            /deep/ .main-r {
                width: 1000px;
                background: #fff;
                padding-bottom: 30px;

                // ------以下是头部tab切换导航样式-----
                .el-tabs__nav {
                    margin-left: 20px;
                }

                .el-tabs el-tabs--top {
                    margin-top: 20px;
                }

                .el-tabs__header {
                    padding-top: 20px;
                }

                .el-tabs__item:hover {
                    color: black !important;
                }

                .el-tabs__active-bar {
                    background: #d82229;
                }

                .el-tabs__item.is-active {
                    color: black;
                }

                .el-tabs__item {
                    background: url("../../../assets/image/purchaseOrder-nav.png") no-repeat 0 8px;
                    background-size: 1px 24px;
                    font-size: 16px;
                }

                #tab-first {
                    background: none;
                }

                // ------以上是头部tab切换导航样式-----

                .dropbtn {
                    width: 135px;
                    height: 30px;
                    border: 1px solid #e5e5e5;
                    margin-left: 20px;
                    background: #fff;
                    color: fff;
                    font-size: 15px;
                    text-align: center;
                    line-height: 28px;
                    cursor: pointer;
                }

                .dropdown-content {
                    position: absolute;
                    left: 20px;
                    background-color: #f9f9f9;
                    min-width: 134px;
                    text-align: center;
                    z-index: 77;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                }

                .dropdown-content a {
                    font-size: 14px;
                    color: black;

                    text-decoration: none;
                    height: 20px;
                    display: block;
                }

                .dropdown-content a:hover {
                    background-color: #d82229;
                    color: #fff;
                }

                .dropbtn {
                    font-size: 14px;
                }

                .dropdown:hover .dropbtn {
                    font-size: 14px;
                }

                .el-tabs__nav-wrap::after {
                    //tab切换下边框样式
                    color: #e5e5e5;
                    height: 1px;
                }

                .ipt1 {
                    width: 193px;
                    height: 30px;
                    border: 1px solid #e5e5e5;
                    margin-left: 30px;
                    padding-left: 12px;
                    color: #999;
                    font-size: 14px;
                }

                .btn1 {
                    cursor: pointer;
                    width: 46px;
                    height: 30px;
                    margin-left: 18px;
                    background: #d82229;
                    border: 0;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 2px;
                    outline: none;
                }

                .div2 {
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin: 0 auto;
                    margin-top: 23px;
                    height: 43px;
                    background: #f5f5f5;
                    border: 1px solid #e5e5e5;

                    > div {
                        float: left;
                    }

                    line-height: 44px;

                    .div2-1 {
                        margin-left: 90px;
                    }

                    .price {
                        margin-left: 156px;
                    }

                    .number {
                        margin-left: 78px;
                    }

                    .types{
                        margin-left:80px;
                    }
                    .div2-2 {
                        margin-left: 66px;
                    }

                    .status {
                        margin-left: 48px;
                        display: flex;
                        justify-content: center;
                        .el-dropdown {
                            cursor: pointer;
                        }

                        
                    }

                    .div2-4 {
                        margin-left: 27px;
                    }
                }

                .ul1 {
                    width: 962px;
                    margin: 0 auto;
                    margin-top: 20px;

                    li {
                        .divt {
                            width: 100%;
                            height: 44px;
                            display: flex;
                            line-height: 44px;
                            color: #666666;
                            background: #f5f5f5;
                            border-bottom: 1px solid #e5e5e5;

                            span {
                                margin-left: 29px;
                            }

                            .span1 {
                                margin-left: 14px;
                            }

                            .span3 {
                                text-align: center;
                            }
                            .upload,.echoWrap{
                                width: 234px;
                                height: 100%;
                                color: #fff;
                                margin-left: auto;
                                margin-right: 15px;
                                background: url("../../../assets/image/ccc-bg.png") no-repeat;
                                // padding-left: 55px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img{
                                    display: block;
                                    // width: 10px;
                                    height: 18px;
                                }
                                span{
                                    margin: 0;
                                    margin: 0 10px;
                                }
                            }
                            .upload{
                                cursor: pointer;
                            }
                            .echoWrap{
                                cursor: pointer;
                                background: url("../../../assets/image/blue-bg.png") no-repeat;
                            }
                             .echoWrap1{
                                  width: 234px;
                                height: 100%;
                                color: #666;
                                margin-left: auto;
                                margin-right: 15px;
                                // background: url("../../../assets/image/ccc-bg.png") no-repeat;
                                // padding-left: 55px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img{
                                    display: block;
                                    width: 10px;
                                    height: 18px;
                                }
                                span{
                                    margin: 0;
                                    margin: 0 10px;
                                }
                                cursor: pointer;
                            }
                        }

                        .divb {
                            width: 100%;
                            height: 120px;

                            .divb-l {
                                height: 120px;
                                float: left;

                                > div {
                                    float: left;
                                }

                                .divb-l2,
                                .divb-l3 {
                                    position: relative;
                                    font-size: 15px;
                                    width: 120px;
                                    float: left;
                                    text-align: center;
                                    height: 100px;
                                    line-height: 120px;
                                }

                                .divb-l3 {
                                    height: 100px;
                                    width: 100px;
                                }

                                .divb-l1 {
                                    width: 248px;
                                    height: 81px;
                                    display: flex;
                                    margin-left: 15px;
                                    margin-top: 20px;
                                    .imgWrap {
                                        height: 100px;
                                    }
                                    p {
                                        position: relative;
                                    }
                                    
                                    .productWrap{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        .product {
                                            width: 120px;
                                            // margin-top: 5px !important;
                                            margin-bottom: 0 !important;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                    }

                                    img {
                                        margin-right: 10px;
                                        float: left;
                                        display: block;
                                        width: 81px;
                                        height: 81px;
                                    }

                                    color: #333;
                                    font-size: 15px;

                                    div {
                                        line-height: 20px;
                                        margin: 0 !important;

                                        p {
                                            line-height: 18px;
                                            color: #999;
                                            font-size: 14px;
                                        }

                                        .first_p {
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }

                            .divb-c {
                                float: left;
                                width: 90px;
                                height: 100%;
                                line-height: 120px;
                                color: #ff425e;
                                text-align: center;
                                margin-left: 20px;
                            }

                            .divb-r {
                                float: right;
                                width: 339px;
                                height: 120px;

                                > div {
                                    float: left;
                                }

                                .divb-rl {
                                    width: 110px;
                                    text-align: center;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    p {
                                        
                                        text-align: center;
                                    }
                                }

                                .divb-rc {
                                    width: 113px;
                                    height: 100%;
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;
                                    flex-direction: column;
                                    padding:25px 0;
                                    .p1 {
                                        text-align: center;
                                    }

                                    .p2 {
                                        color: #343434;
                                        text-align: center;
                                    }
                                }

                                .divb-rr {
                                    display: flex;
                                    width: 111px;
                                    height: 100%;
                                     justify-content: space-around;
                                    align-items: center;
                                    flex-direction: column;
                                    padding:25px 0;
                                    font-size: 15px;
                                        
                                    .p1 {
                                        margin-left: 17px;
                                        width: 78px;
                                        height: 26px;
                                        cursor: pointer;
                                        color: #fff;
                                        text-align: center;
                                        line-height: 26px;
                                        text-align: center;
                                    }

                                    > button {
                                        font-size: 14px;
                                        padding: 5px;
                                        background: #d82229;
                                        border-radius: 3px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        outline: none;
                                        cursor: pointer;
                                    }

                                    .p2 {
                                        margin-top: 16px;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }

                .div3 {
                    padding-left: 30px;
                }

                .div4 {
                    width: 960px;
                    border: 1px solid #e5e5e5;
                    margin: 0 auto;

                    .div4-1 {
                        width: 960px;
                        height: 44px;
                        background: #f5f5f5;
                        margin: 0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;

                        span {
                            margin-left: 125px;
                        }

                        .span2 {
                            margin-left: 250px;
                        }

                        .span3 {
                            margin-left: 145px;
                        }

                        .span4 {
                            margin-left: 160px;
                        }
                    }

                    .ul2 {
                        margin: 0 auto;
                        width: 960px;
                        height: 120px;

                        li {
                            width: 960px;
                            height: 120px;

                            .ul2-lil {
                                width: 327px;
                                height: 100%;
                                // background: blue;
                                float: left;
                            }

                            .ul2-lir {
                                width: 536px;
                                height: 100%;
                                float: right;
                            }
                        }
                    }
                }

                .div5 {
                    width: 960px;
                    margin: 0 auto;
                    height: 94px;
                    line-height: 94px;
                    text-align: right;

                    span {
                        margin-right: 30px;
                    }

                    .teshu {
                        color: #fe0036;
                    }

                    .btn1 {
                        width: 100px;
                        height: 36px;

                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color: #fff;
                    }
                }
            }
        }
    }

    .pagination {
        width: 800px;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        margin-top: 60px;
    }

    .el-message-box--center {
        display: none !important;
    }

    // 以下分页样式
    /deep/ .el-pagination {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
    }


//查看业务单
.quickOrder{
    .basisInfo{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    >div{
        width: 28%;
        height: 40px;
        margin-bottom: 20px;
        margin-right: 1%;
        display: flex;
        font-size: 14px;
        // background: red;
        p{
            line-height: 40px;
        }
        >:first-child{
            flex-shrink: 0;
        }
        >:last-child{
            width: 70%;
            border: 1px solid #e5e5e5;
            padding-left: 10px;
            padding-right: 19px;
        }
    }
    >:nth-child(3n){
        width: 40%;
        :last-child{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .orgName,.orgAddr{
        margin-right: 0;
        width: 42%;
        display: flex;
        justify-content: space-between;
        :last-child{
            width: 81%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
     
}
.tabWrap{
    /deep/ .el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{
        padding:0;
        text-align: center;
    }
    /deep/ .el-table .cell{
        text-align: center;
    }
    .el-table{
        width: 100% !important;
    }
     /* 表格内背景颜色 */
 /deep/ .el-table__body tr.current-row>td{
     background: #fff;
 }
  /* 使表格背景透明 */
/deep/ .el-table th, .el-table tr {
 background-color: transparent;
 }

 /deep/ .el-table th, .el-table tr,.el-table td{
     border-bottom: 1px solid #eee !important;    
    background-color: transparent;
}

}

.basisInfo1{
    margin-top: 20px;
    display: block;
    >div{
        display: flex;
        line-height: 40px;
        >:first-child{
            width: 80px;
            padding-right: 10px;
            text-align: right;
        }
    }
}

.btnWrap{
    margin:30px;
    margin-left: 80px;
    display: flex;
    .subBtn,.cancelBtn{
        outline: none;
        border: none;
        background:#d82229;
        padding: 8px 10px;
        border-radius: 3px;
        color: #fff ;
        cursor: pointer;
        margin-right: 20px;
    }
    .cancelBtn{
        padding: 8px 15px;
        background:none;
        border:1px solid #999;
        color: #999 !important;
        box-sizing:border-box;
    }
}
/deep/ .el-dialog__body{
    padding-top: 0;
}
 .title{
        text-align: center;
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 20px;
        color: #333;
    }
}
</style>
